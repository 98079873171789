* {
  font-size: 14px;
}

#root {
  margin: auto;
  width: 1000px;
  position: relative;
}

.wrapper {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.wrapper > div {
  flex: 1 1 150px;
}

.wrapperNarrow {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
}

.wrapperNarrow > div {
  flex: 1 1 100px;
}

.wrapperNarrow > .buildingContainer {
  position: relative;
  flex: 1 1 125px;
  padding: 1px 10px;
}

.cardRow {
  position: relative;
  padding: 1px 10px;
}
.cardName {
  clear: both;
  float: left;
}
.cardCount {
  float: right;
}

.active {
  border: 2px solid green;
}
.inactive {
  border: 2px solid tomato;
}

.goodThingHappened {
  animation: fadeOutAdded 3s;
}
@keyframes fadeOutAdded {
  from { background-color: lightgreen; }
  to { background-color: white; }
}
.badThing {
  color: tomato;
}
.badThingHappened {
  animation: fadeOutRemoved 3s;
}
@keyframes fadeOutRemoved {
  from { background-color: lightSalmon; }
  to { background-color: white; }
}

.clear {
  clear: both;
}

.stores, .statuses, .villagerDetailsContainer {
  position: relative;
  z-index: 10;
  cursor: default;
  padding: 5px 0px;
}

.infos {
  margin: 2px;
  border: 1px solid black;
  display: block;
}

.log {
  width: 150px;
  margin-top: 1em;
  padding: 0.25em;
  overflow: hidden;
}

.buildingCard {
    height: 3em;
}

.villagerButton {
    float: right;
}

/* this is for mobile */
@media (max-width: 768px) {
  .wrapper {
    width: 350px;
    display: flex;
    flex-wrap: wrap;
  }

  .wrapper > div {
    flex: 1 1 50px;
  }

  #root {
    margin: auto;
    width: auto;
    position: relative;
  }
}
